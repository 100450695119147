import moment from 'moment'
import { Bar, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { useEffect, useState } from 'react'

import ErrorBoundary from './error-boundary'
import Loading from './loading'

const ACTIVITY_COLOUR = '#6ed0eb' // theme blue

const FEELING_COLOURS = {
    mood: '#a855f7', // purple
    fatigue: '#eab308', // yellow
    focus: '#10b981', // green
}

const METRIC_COLOURS = [
    '#10b981', // green
    '#a855f7', // purple
    '#eab308', // yellow
    '#3b82f6', // blue
    '#ec4899', // pink
    '#f97316', // orange
]

export function FeelingsTrend({ activity, days }) {
    const [data, setData] = useState()

    useEffect(() => {
        let startDate = moment().subtract(28, 'days').startOf('day'),
            _data = [],
            dt,
            feelings
        for (let i = 0; i < 28; i++) {
            dt = startDate.add(1, 'days')
            feelings = days.find(({ date }) => moment(date).isSame(dt, 'day'))?.feelingMetrics

            _data.push({
                date: dt.toDate(),
                activeMinutes: activity.filter((d) => moment(d.date).isSame(dt, 'day')).reduce((t, d) => (t += d.duration), 0),
                mood: feelings?.find(({ title }) => title === 'Mood')?.value ?? null,
                focus: feelings?.find(({ title }) => title === 'Focus')?.value ?? null,
                fatigue: feelings?.find(({ title }) => title === 'Fatigue')?.value ?? null,
            })
        }

        setData(_data)
    }, [])

    if (!data) return <Loading />

    let recentActivity = activity.filter(({ date }) => moment(date).isAfter(moment().subtract(28, 'days')))
    let recentFeelings = days.filter(({ date }) => moment(date).isAfter(moment().subtract(28, 'days')))

    if (recentActivity.length === 0 && recentFeelings.length === 0) {
        return <div className='m-4 text-lg text-center text-sky-800'>Record some activity and how you've been feeling to see recent trends</div>
    }

    return (
        <ErrorBoundary componentName='Dashboard -> FeelingsTrend -> Render logic'>
            <div className='flex flex-col'>
                {recentActivity.length === 0 && <div className='mt-4 mb-1 text-lg text-center text-sky-800'>Record some recent activity to see a trend comparison</div>}
                {recentFeelings.length === 0 && <div className='mt-4 mb-1 text-lg text-center text-sky-800'>Record how you've been feeling to see a trend comparison</div>}
                <ResponsiveContainer height={400} width='100%'>
                    <ComposedChart data={data} margin={{ top: 20, bottom: 10 }}>
                        <CartesianGrid stroke='#f5f5f5' />
                        <XAxis dataKey='date' tickFormatter={(t) => moment(t).format('Do MMM')} />

                        <YAxis yAxisId='activity' orientation='left' dataKey='activeMinutes' />
                        <Bar dataKey='activeMinutes' fill={ACTIVITY_COLOUR} minPointSize={3} shape={<rect rx={3} />} name='Activity' unit=' mins' yAxisId='activity' opacity={0.4} />

                        <YAxis yAxisId='feeling' orientation='right' ticks={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]} />
                        {['mood', 'fatigue', 'focus'].map((key) => (
                            <Line connectNulls yAxisId='feeling' dataKey={key} name={key.replace(key[0], key[0].toUpperCase())} fill={FEELING_COLOURS[key]} stroke={FEELING_COLOURS[key]} strokeWidth={2} />
                        ))}

                        <Legend />
                        <Tooltip labelFormatter={(d) => moment(d).format("ddd Do MMM 'YY")} />
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        </ErrorBoundary>
    )
}

export function MetricsTrend({ activity, metrics }) {
    const [data, setData] = useState()
    const [metricsList, setMetricsList] = useState()

    useEffect(() => {
        let uniqueMetrics = [...new Set(metrics.map(({ type }) => type))]
        setMetricsList(uniqueMetrics)

        let _data = [],
            dt = moment().subtract(28, 'days').startOf('day'),
            entry,
            _metrics
        for (let i = 0; i < 28; i++) {
            dt = dt.add(1, 'days')

            _metrics = metrics.filter(({ date }) => moment(date).isSame(dt, 'day'))

            entry = {
                date: dt.toDate(),
                activeMinutes: activity.filter((d) => moment(d.date).isSame(dt, 'day')).reduce((t, d) => (t += d.duration), 0),
            }

            for (const metric of uniqueMetrics) {
                entry[metric.split(' ').join('_')] = _metrics?.find(({ type }) => type === metric)?.value ?? null
            }

            _data.push(entry)
        }

        setData(_data)
    }, [metrics])

    if (!data || !metricsList) return <Loading />

    let recentActivity = activity.filter(({ date }) => moment(date).isAfter(moment().subtract(28, 'days')))
    let recentMetrics = metrics.filter(({ date }) => moment(date).isAfter(moment().subtract(28, 'days')))

    if (recentActivity.length === 0 && recentMetrics.length === 0) {
        return <div className='m-4 text-lg text-center text-sky-800'>Record some activity and health metrics to see recent trends</div>
    }

    return (
        <ErrorBoundary componentName='Dashboard -> FeelingsTrend -> Render logic'>
            <div className='flex flex-col'>
                {recentActivity.length === 0 && <div className='mt-4 mb-1 text-lg text-center text-sky-800'>Record some recent activity to see a trend comparison</div>}
                {recentMetrics.length === 0 && <div className='mt-4 mb-1 text-lg text-center text-sky-800'>Record some health metrics to see a trend comparison</div>}
                <ResponsiveContainer height={400} width='100%'>
                    <ComposedChart data={data} margin={{ top: 20, bottom: 10, right: 50 }}>
                        <CartesianGrid stroke='#f5f5f5' />
                        <XAxis dataKey='date' tickFormatter={(t) => moment(t).format('Do MMM')} />

                        <YAxis yAxisId='activity' orientation='left' dataKey='activeMinutes' />
                        <Bar dataKey='activeMinutes' fill={ACTIVITY_COLOUR} minPointSize={3} shape={<rect rx={3} />} name='Activity' unit=' mins' yAxisId='activity' opacity={0.4} />

                        <YAxis yAxisId='metricIdx' orientation='right' hide />
                        {metricsList.map((metric, idx) => (
                            <Line connectNulls yAxisId='metricIdx' dataKey={metric.split(' ').join('_')} name={metric} fill={METRIC_COLOURS[idx]} stroke={METRIC_COLOURS[idx]} strokeWidth={2} />
                        ))}

                        <Legend />
                        <Tooltip labelFormatter={(d) => moment(d).format("ddd Do MMM 'YY")} />
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        </ErrorBoundary>
    )
}
