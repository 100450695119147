import { ExportToCsv } from 'export-to-csv'
import JSZip from 'jszip'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { api, api_delete } from '../services/api.service'

import Button from '../components/button'
import Confirm from '../components/confirm'
import Loading from '../components/loading'
import PageHeader from '../components/page-header'
import Table from '../components/table'

export default function AdminUsers() {
    const navigate = useNavigate()
    const [users, setUsers] = useState(null)
    const [deleteId, setDeleteId] = useState()

    useEffect(() => {
        api(`${process.env.REACT_APP_API_URL}/admin/user`).then((x) => setUsers(x))
    }, [])

    function deleteConfirm() {
        api_delete(`${process.env.REACT_APP_API_URL}/admin/user/${deleteId}`).then((x) => {
            api(`${process.env.REACT_APP_API_URL}/admin/user`)
                .then((x) => setUsers(x))
                .then((x) => setDeleteId(null))
        })
    }

    function exportCSV() {
        api(`${process.env.REACT_APP_API_URL}/admin/csv`).then((data) => {
            zipDownload(data)
        })
    }

    if (!users) return <Loading></Loading>

    return (
        <>
            <Confirm open={!!deleteId} cancel={() => setDeleteId(false)} confirm={() => deleteConfirm(deleteId)}></Confirm>

            <PageHeader headline={'Users'} actions={<Button text='Add User' onClick={() => navigate('/admin/manage-user/new')}></Button>}></PageHeader>

            <div className='flex'>
                <div className='w-full max-w-5xl pb-20 mx-auto my-10 2xl:max-w-6xl'>
                    <div>
                        <Button text='Export to CSV' onClick={() => exportCSV()} />
                        <Table
                            data={users.map((x) => {
                                return {
                                    ...x,
                                    data: (
                                        <span className='underline cursor-pointer' onClick={() => navigate(`/admin/user/${x._id}/data`)}>
                                            View Data
                                        </span>
                                    ),
                                    edit: (
                                        <span className='underline cursor-pointer' onClick={() => navigate(`/admin/manage-user/${x._id}`)}>
                                            Edit
                                        </span>
                                    ),
                                    deleteCell: (
                                        <span className='underline cursor-pointer' onClick={() => setDeleteId(x._id)}>
                                            Delete
                                        </span>
                                    ),
                                }
                            })}
                            columns={[
                                {
                                    Header: 'Email',
                                    accessor: 'email',
                                },
                                {
                                    Header: 'View Data',
                                    accessor: 'data',
                                },
                                {
                                    Header: 'Edit',
                                    accessor: 'edit',
                                },
                                {
                                    Header: 'Delete',
                                    accessor: 'deleteCell',
                                },
                            ]}
                        ></Table>
                    </div>
                </div>
            </div>
        </>
    )
}

async function zipDownload(data) {
    var options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useTextFile: false,
        useBom: true,
    }

    const exporter = new ExportToCsv(options)
    const zip = new JSZip()

    exporter.options.headers = ['uuid', 'utc_timestamp', 'metric_name', 'metric_value']
    zip.file('Agewell_Metrics_Data.csv', exporter.generateCsv(data.metrics, true))

    exporter.options.headers = ['uuid', 'utc_timestamp', 'activity_title', 'activity_duration_minutes']
    zip.file('Agewell_Activity_Data.csv', exporter.generateCsv(data.activity, true))

    exporter.options.headers = ['uuid', 'utc_timestamp', 'feeling_notes', 'rating_name', 'rating_name_value']
    zip.file('Agewell_Feelings_Data.csv', exporter.generateCsv(data.feelings, true))

    var blob = await zip.generateAsync({ type: 'blob' })
    if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, 'Agewell_User_Data.zip')
    } else {
        var link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.setAttribute('visibility', 'hidden')
        link.download = 'Agewell_User_Data.zip'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }
}
