import { Combobox, Transition } from '@headlessui/react'
import { ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { Fragment, useEffect, useState } from 'react'

import Loading from '../../components/loading'

export default function SelectField({ label, value, onChange, className, inputClass, labelClass, options, optionListName, placeholder, required }) {
    const [selectOptions, setSelectOptions] = useState()
    const [query, setQuery] = useState('')
    const filtered = query === '' ? selectOptions : selectOptions.filter((x) => x?.text.toLowerCase().includes(query.toLowerCase()))

    useEffect(() => {}, [optionListName])

    useEffect(() => {
        setSelectOptions(options)
    }, [options])

    if (!selectOptions) return <Loading></Loading>

    return (
        <div className={className ?? 'mt-2'}>
            <label className={`block text-sm font-medium leading-6 text-neutral-600 ${labelClass}`}>
                {label} {required && <span className='text-red-500'>*</span>}
            </label>
            <div className={`relative rounded shadow-sm ${label ? 'mt-1' : ''}`}>
                <Combobox
                    value={selectOptions.find((x) => x.value === value)}
                    onChange={(e) => {
                        onChange(e)
                    }}
                >
                    <div className='relative mt-1'>
                        <div className='relative w-full overflow-hidden text-left border border-gray-200 rounded cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-green-300 sm:text-sm'>
                            <Combobox.Input placeholder={placeholder} className='w-full py-2 pl-3 pr-10 text-sm leading-5 border-none bg-slate-100 text-neutral-700 focus:ring-0' onChange={(e) => setQuery(e.target.value)} displayValue={(x) => x?.text} required={required} />
                            <Combobox.Button className='absolute inset-y-0 right-0 flex items-center pr-2'>
                                <ChevronUpDownIcon className='w-5 h-5 text-gray-400' aria-hidden='true' />
                            </Combobox.Button>
                        </div>
                        <Transition as={Fragment} leave='transition ease-in duration-100' leaveFrom='opacity-100' leaveTo='opacity-0' afterLeave={() => setQuery('')}>
                            <Combobox.Options className='absolute z-40 w-full py-1 mt-1 overflow-auto text-base bg-white rounded shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                                {filtered.map((x) => (
                                    <Combobox.Option key={x.value} value={x.value} className={({ active }) => `relative cursor-default select-none py-2 px-3  ${x.value === value ? 'bg-green-600 text-white' : `${x.value !== value && active ? 'bg-green-400 text-white' : 'text-neutral-700'}`} `}>
                                        {x.text}
                                    </Combobox.Option>
                                ))}
                            </Combobox.Options>
                        </Transition>
                    </div>
                </Combobox>
            </div>
        </div>
    )
}
