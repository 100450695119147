import logo from '../logo.png'

export default function AuthLayout({ children }) {
    return (
        <div className='flex flex-col min-h-screen overflow-auto bg-slate-50'>
            <div className='flex flex-col justify-center flex-1'>
                <div className='sm:mx-auto sm:w-full sm:max-w-md'>
                    <img className='w-auto h-40 mx-auto mb-2' src={logo} alt='' />
                </div>
                <div className='pb-10 mt-4 sm:mx-auto sm:w-full sm:max-w-lg'>
                    <div className='px-4 py-8 bg-white rounded shadow sm:px-10'>{children}</div>
                </div>
            </div>
        </div>
    )
}
