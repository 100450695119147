import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'

import CookieConsent from 'react-cookie-consent'

import logo from '../logo.png'

export default function AppLayout() {
    return (
        <>
            <div className='flex flex-col min-h-screen min-w-screen bg-slate-50'>
                <AppHeader />

                <div className='flex flex-col items-center flex-1'>
                    <div className='flex flex-col w-full px-2 mt-4 mb-16 md:max-w-3xl'>
                        <Outlet></Outlet>
                    </div>
                </div>
            </div>
            <ToastContainer position='bottom-right' theme='light' autoClose={3000} />
            <CookieConsent
                // debug={true}
                location='bottom'
                buttonText='OK'
                cookieName='cookie_consent'
                style={{
                    background: 'white',
                    color: '#0284c7',
                    padding: '.25rem 1rem',
                    boxShadow: '0 -10px 6px 0 rgba(0,0,0,0.2)',
                    borderTop: '1px solid #bae6fd',
                }}
                buttonStyle={{
                    background: '#0284c7',
                    color: 'white',
                    borderRadius: '4px',
                    padding: '.25rem 1rem',
                }}
            >
                This website uses cookies to enhance the user experience. For full details of how we use cookies, please read our{' '}
                <a target='_blank' href='/cookie-policy' className='text-green-400 hover:text-green-500'>
                    Cookie Policy
                </a>
            </CookieConsent>
        </>
    )
}

const NAV_LINKS = [
    { text: 'Dashboard', link: '/' },
    { text: 'Activity', link: '/activity' },
    { text: 'Metrics', link: '/metrics' },
    { text: 'Resources', link: '/resources' },
    { text: 'About Us', link: '/about' },
]

export function AppHeader() {
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)

    return (
        <div className='sticky top-0 left-0 right-0 z-50 flex items-center justify-between px-6 py-2 bg-white shadow-md select-none'>
            {/* Brand */}
            <div className='flex items-center cursor-pointer' onClick={() => navigate('/')}>
                <img src={logo} width={64} height={64} alt='Agewell' />
            </div>

            {/* Header Nav */}
            <div className='hidden sm:block'>
                <nav className='flex items-center space-x-6'>
                    {NAV_LINKS.map((d) => (
                        <a key={d.link} href={d.link} className='font-semibold no-underline text-accent-blue hover:text-sky-700'>
                            {d.text}
                        </a>
                    ))}
                    <a href='/logout' className='px-2 py-1 font-semibold no-underline bg-white border rounded shadow-sm border-accent-pink hover:bg-neutral-100 text-accent-pink'>
                        Logout
                    </a>
                </nav>
            </div>
            {/* Sidebar Nav */}
            <div className='block sm:hidden'>
                <div className='cursor-pointer text-accent-blue' onClick={() => setOpen(true)}>
                    <Bars3Icon className='w-8 h-8' />
                </div>
                {open && (
                    <div className='fixed top-0 bottom-0 left-0 right-0 bg-[rgba(0,0,0,0.2)]' onClick={() => setOpen(false)}>
                        <div className='fixed top-0 bottom-0 right-0 flex flex-col px-6 py-2 space-y-10 bg-white border-l shadow-2xl min-w-fit border-accent-blue' onClick={(e) => e.stopPropagation()}>
                            <div className='flex justify-end w-full'>
                                <div className='cursor-pointer text-accent-blue h-[72px] flex' onClick={() => setOpen(false)}>
                                    <XMarkIcon className='w-8 h-8 my-auto' />
                                </div>
                            </div>
                            <nav className='flex flex-col items-end px-6 space-y-4'>
                                {NAV_LINKS.map((d) => (
                                    <a key={d.link} href={d.link} className='font-semibold no-underline text-accent-blue hover:text-sky-600'>
                                        {d.text}
                                    </a>
                                ))}
                                <a href='/logout' className='px-2 py-1 font-semibold no-underline bg-white border rounded shadow-sm border-accent-pink hover:bg-neutral-100 text-accent-pink'>
                                    Logout
                                </a>
                            </nav>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
