import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { api } from '../services/api.service'

import Card from '../components/card'
import { FeelingsTrend, MetricsTrend } from '../components/charts'
import ErrorBoundary from '../components/error-boundary'
import Loading from '../components/loading'
import WeekTargetRing from '../components/week-target-ring'

export default function Home() {
    const navigate = useNavigate()
    const [user, setUser] = useState()
    const [activity, setActivity] = useState()
    const [days, setDays] = useState()
    const [metrics, setMetrics] = useState()

    useEffect(() => {
        api(`${process.env.REACT_APP_API_URL}/app`).then(({ user, activities, days, healthMetrics }) => {
            setUser(user)
            setActivity(activities)
            setDays(days)
            setMetrics(healthMetrics)
        })
    }, [])

    if (!user || !activity || !days || !metrics) return <Loading />

    return (
        <ErrorBoundary componentName='Dashboard'>
            <div className='flex flex-col space-y-4'>
                <div className='grid grid-cols-1 gap-4 sm:grid-cols-2'>
                    <Card header='Weekly Activity'>
                        <WeekTargetRing activity={activity} />
                    </Card>
                    <Card>
                        <div className='flex items-center justify-center p-4 bg-sky-800'>
                            <div className='text-2xl leading-7 text-white'>{prettyWelcomeText(user?.name)}</div>
                        </div>
                        {metrics.length === 0 ? (
                            <div className='flex items-center justify-center p-4'>
                                <div onClick={() => navigate('/metrics')} className='flex text-lg cursor-pointer text-sky-800 hover:text-sky-700'>
                                    <div>Record a health metric to get started</div>
                                    <ArrowTopRightOnSquareIcon className='w-6 h-6 ml-1' />
                                </div>
                            </div>
                        ) : (
                            <div className='flex flex-wrap justify-center'>
                                {lastUniqueMetrics(metrics).map((metric) => (
                                    <div className='p-3 m-2 border select-none border-sky-500 rounded-xl'>
                                        <div className='text-lg text-sky-700'>{metric.type}</div>
                                        <div className='text-3xl font-bold text-right text-sky-800'>{metric.value}</div>
                                        <div className='text-sm text-right text-accent-blue'>{moment(metric.date).format("ddd Do MMM 'YY")}</div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </Card>
                </div>

                <Card header='Activity x Metrics'>
                    <div className='select-none'>
                        <ErrorBoundary componentName='Dashboard -> MetricsTrend'>
                            <MetricsTrend activity={activity} metrics={metrics} />
                        </ErrorBoundary>
                    </div>
                </Card>
                <Card header='Activity x Feelings'>
                    <div className='select-none'>
                        <ErrorBoundary componentName='Dashboard -> FeelingsTrend'>
                            <FeelingsTrend activity={activity} days={days} />
                        </ErrorBoundary>
                    </div>
                </Card>
            </div>
        </ErrorBoundary>
    )
}

function prettyWelcomeText(name) {
    try {
        let result = 'Hello'
        if (!name) return result

        result += ', '

        let parts = name.split(' ')

        result += parts[0]

        if (parts.length > 1) {
            result +=
                ' ' +
                parts
                    .slice(1)
                    .map((d) => d.split('')[0].toUpperCase())
                    .join(' ')
        }

        return result
    }
    catch (e) {
        return name
    }

}

function lastUniqueMetrics(metrics) {
    let byType = {},
        result = [],
        name,
        recent

    for (const met of metrics) {
        name = met.type
        byType[name] = byType[name] || []
        byType[name].push(met)
    }

    for (const list of Object.values(byType)) {
        recent = list.sort((a, b) => (moment(a.date).isBefore(moment(b.date)) ? 1 : -1))
        result.push(recent[0])
    }

    return result.sort((a, b) => (moment(a.date).isBefore(moment(b.date)) ? 1 : -1))
}
