import moment from 'moment'
import { useEffect, useState } from 'react'
import { Pie, PieChart, Tooltip } from 'recharts'

export default function WeekTargetRing({ activity }) {
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)

    useEffect(() => {
        let dt = moment().startOf('week')
        let _data = activity.filter(({ date }) => moment(date).isSameOrAfter(dt, 'date')).map((d) => ({ name: `${d?.title ?? ''} on ${moment(d.date).format('ddd Do MMM')}`, value: d.duration }))
        let _total = _data.reduce((tot, { value }) => (tot += value), 0)

        setData(_data)
        setTotal(_total)
    }, [])

    return (
        <div className='flex flex-col items-center justify-center'>
            <PieChart height={300} width={300}>
                {/* <Pie data={[{ minutes: 150 }]} dataKey='minutes' cx='50%' cy='50%' outerRadius={99} innerRadius={65} startAngle={0} endAngle={360} fill='var(--accent-blue)' fillOpacity={0.4} strokeWidth={0} isAnimationActive={false} onMouseOver={() => null} /> */}
                <path tabindex='-1' role='img' name='0' cx='150' cy='150' fill='var(--accent-blue)' fill-opacity='0.4' stroke-width='0' stroke='#fff' class='recharts-sector' d='M 249,150 A 99,99,0, 1,0, 248.99999998492143,150.00172787595938 L 214.99999999009992,150.00113446401375 A 65,65,0, 1,1, 215,150 Z'></path>
                <text x={'48%'} y={140} textAnchor='middle' fontSize={28} fontFamily='Lucida Sans'>
                    {total}
                </text>
                <text x={'56%'} y={160} textAnchor='middle' fontSize={16} fontFamily='Lucida Sans'>
                    / 150
                </text>
                <text x={'50%'} y={190} textAnchor='middle' fontSize={12} fontFamily='Lucida Sans'>
                    weekly active
                    <tspan dy={12} x={'50%'}>
                        minutes
                    </tspan>
                </text>
                <Pie data={data} dataKey='value' cx='50%' cy='50%' outerRadius={100} innerRadius={64} fill='var(--accent-pink)' startAngle={90} endAngle={(total / 150) * 360 + 90} strokeWidth={2} />
                <Tooltip content={({ payload }) => <div className='p-4 bg-white rounded ring-1 ring-sky-400'>{`${payload[0]?.name}: ${payload[0]?.value} mins`}</div>} />
            </PieChart>
            <p className='w-full px-4 pb-4 text-center text-sky-600'>
                {total < 150 ? (
                    <>
                        {`You're ${150 - total} minutes away from the weekly target`}
                        <br />
                        <span className='text-sky-400'>{`Try to record ${Math.round((150 - total) / (moment().endOf('isoWeek').diff(moment().startOf('day'), 'days') + 1))} minutes per day for the rest of the week`}</span>
                    </>
                ) : (
                    "You've achieved the weekly activity target"
                )}
            </p>
        </div>
    )
}
