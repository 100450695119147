import moment from 'moment'
import { ArrowLeftCircleIcon, ArrowRightCircleIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { api } from '../services/api.service'

import Card from '../components/card'

import { TextAreaField } from '../forms/fields'
import FormWrapper from '../forms/form-wrapper'

const FEELING_COLOURS = {
    mood: '#a855f7', // purple
    fatigue: 'blue', // yellow
    focus: 'green', // green
}

export default function FeelingsCard() {
    const [days, setDays] = useState()
    const [current, setCurrent] = useState({
        date: moment(),
        data: null,
    })

    useEffect(() => {
        loadDays()
    }, [])

    useEffect(() => {
        setCurrent({ ...current, data: days?.find(({ date }) => current.date.isSame(moment(date), 'day')) })
    }, [days])

    function loadDays() {
        api(`${process.env.REACT_APP_API_URL}/app/days`).then((x) => setDays(x))
    }

    return (
        <Card header='How have you been feeling?'>
            <div className='m-4 select-none text-sky-800'>
                <div className='grid grid-cols-5 mb-4 gap-x-2'>
                    <div className='flex justify-end'>
                        <ArrowLeftCircleIcon
                            className='w-8 h-8 hover:text-sky-600'
                            onClick={() => {
                                let next = current.date.subtract(1, 'days')
                                setCurrent({ date: next, data: days?.find(({ date }) => next.isSame(moment(date), 'day')) })
                            }}
                        />
                    </div>
                    <div className='col-span-3 text-xl text-center'>{moment().isSame(current.date, 'day') ? 'Today' : <>{moment().subtract(1, 'days').isSame(current.date, 'day') ? 'Yesterday' : <>{current.date.format('ddd Do MMM YYYY')}</>}</>}</div>
                    <div>
                        <ArrowRightCircleIcon
                            className={`w-8 h-8 ${moment().isSame(current.date, 'day') ? 'opacity-30' : 'cursor-pointer hover:text-sky-600'}`}
                            onClick={() => {
                                if (moment().isSame(current.date, 'day')) {
                                    return
                                }
                                let next = current.date.add(1, 'days')
                                setCurrent({ date: next, data: days?.find(({ date }) => next.isSame(moment(date), 'day')) })
                            }}
                        />
                    </div>
                </div>
                <FormWrapper
                    url='app/days'
                    id={current.data?._id ?? 'new'}
                    defaultValue={{
                        date: current.date,
                        feelingNotes: '',
                        feelingMetrics: [
                            { title: 'Fatigue', value: 1 },
                            { title: 'Mood', value: 1 },
                            { title: 'Focus', value: 1 },
                        ],
                    }}
                    noStyle
                    callback={() => {
                        toast.success('Ratings Saved')
                        loadDays()
                    }}
                >
                    {(values, setValues) => (
                        <div className='flex flex-col space-y-3'>
                            {values.feelingMetrics.map((met) => (
                                <div className='flex flex-col space-y-2'>
                                    <div className='flex items-end space-x-2'>
                                        <label htmlFor={met.title + 'range'} className='text-lg'>{`${met.title}`}</label>
                                        <span className='text-xl font-semibold'> - {met.value}</span>
                                    </div>
                                    <input id={met.title + 'range'} type='range' value={met.value} style={{ accentColor: FEELING_COLOURS[met.title.toLowerCase()] }} onChange={(e) => setValues({ ...values, feelingMetrics: values.feelingMetrics.map((d) => (d.title === met.title ? { ...met, value: Number(e.target.value) } : d)) })} min={1} max={10} />
                                    <div className='flex flex-row justify-between'>
                                        <h1>Low</h1>
                                        <h1>High</h1>
                                    </div>
                                </div>
                            ))}
                            <TextAreaField label='Describe in more detail' value={values.feelingNotes} onChange={(val) => setValues({ ...values, feelingNotes: val })} />
                        </div>
                    )}
                </FormWrapper>
            </div>
        </Card>
    )
}
