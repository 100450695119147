import { useEffect, useRef, useState } from 'react'

import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'

import { Document, Page, pdfjs } from 'react-pdf'

export default function Consent() {
    const [numPages, setNumPages] = useState(null)

    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
    }, [])

    const [initialWidth, setInitialWidth] = useState(null)
    const pdfWrapper = useRef()

    const setPdfSize = () => {
        if (pdfWrapper && pdfWrapper.current) {
            setInitialWidth(pdfWrapper.current.getBoundingClientRect().width - 20)
        }
    }

    useEffect(() => {
        window.addEventListener('resize', setPdfSize())
        setPdfSize()
        return () => {
            window.removeEventListener('resize', setPdfSize())
        }
    }, [])

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages)
    }

    return (
        <div className='flex-1 w-full h-full' ref={pdfWrapper}>
            <Document onLoadError={console.error} onLoadSuccess={onDocumentLoadSuccess} file={'/Agewell_ConsentForm.pdf'}>
                {Array.from(new Array(numPages), (el, index) => (
                    <Page renderTextLayer={false} renderAnnotationLayer={false} width={initialWidth} key={`page_${index + 1}`} pageNumber={index + 1} />
                ))}
            </Document>
        </div>
    )
}
