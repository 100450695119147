import Card from '../components/card'

export default function About() {
    return (
        <div className='space-y-6'>
            <Card header='About Us'>
                <div className='p-6'>
                    We are two health professionals who are passionate about healthy ageing in perimenopausal and menopausal women.
                    <br />
                    <br />
                    We grew tired of hearing that sitting is the new smoking, and that women spend more years in poor health than men.
                    <br />
                    <br />
                    Using the Government guidance of 150 minutes activity a week to maintain good health, we use accountability and motivation for including physical activity in the day and provide support to build an active lifestyle.
                </div>
            </Card>
            <Card header='Physical activity for better health outcomes'>
                <div className='p-6'>
                    Our mission is to help you move more, build resilience and better health so that you can live well for longer.
                    <br />
                    <br />
                    We focus on improving blood pressure, blood sugar and cholesterol
                    <br />
                    <br />
                    The 12-week programme monitors your progress, gives accountability and supports you to build a level of activity that you can sustain and improve your health outcomes.
                </div>
            </Card>
            <Card header='How are we different?'>
                <div className='p-6'>
                    <ul className='space-y-4'>
                        <li>
                            <b>01</b> We take evidence based scientific research on the risks of chronic disease in women, and focus on how these risks can be reduced
                        </li>
                        <li>
                            <b>02</b> Linking activity tracking to metabolic metrics shows you the impact your activity is having on your health
                        </li>
                        <li>
                            <b>03</b> Logging mood helps create awareness of triggers for avoiding activity.
                        </li>
                        <li>
                            <b>04</b> We provide support and progression to help you reach your target
                        </li>
                    </ul>
                </div>
            </Card>
        </div>
    )
}
