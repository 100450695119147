import { useEffect, useRef, useState } from 'react'

import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'

import { Document, Page, pdfjs } from 'react-pdf'

export default function Terms() {
    const [numPages, setNumPages] = useState(null)

    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
    }, [])

    const [initialWidth, setInitialWidth] = useState(null)
    const pdfWrapper = useRef()

    const setPdfSize = () => {
        if (pdfWrapper && pdfWrapper.current) {
            setInitialWidth(pdfWrapper.current.getBoundingClientRect().width - 20)
        }
    }

    useEffect(() => {
        window.addEventListener('resize', setPdfSize())
        setPdfSize()
        return () => {
            window.removeEventListener('resize', setPdfSize())
        }
    }, [])

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages)
    }

    return (
        <div className='flex-1 w-full h-full' ref={pdfWrapper}>
            <Document onLoadError={console.error} onLoadSuccess={onDocumentLoadSuccess} file={'/Agewell_TermsOfService.pdf'}>
                {Array.from(new Array(numPages), (el, index) => (
                    <Page renderTextLayer={false} renderAnnotationLayer={false} width={initialWidth} key={`page_${index + 1}`} pageNumber={index + 1} />
                ))}
            </Document>
        </div>
    )
}

export function XTerms() {
    return (
        <div className='m-6 legal-page'>
            <h1>Coaching agreement for Agewell programme</h1>
            <h2 className='italic text-neutral-600'>Last updated October 2023.</h2>
            <ol>
                1. Application of terms and conditions
                <li>
                    1.1. These terms and conditions (“Terms”) apply to the Agewell coaching Programme (“Programme”) operated by Agewell Solutions Ltd, a company registered in Northern Ireland under company number NI699569 with its registered office at 29 Bridge Street, Lisburn, Northern Ireland, BT28 1XZ ("Agewell", “we”, “us” or “our”). By registering to be a participant in our Programme, you agree to the following Terms and that these prevail over any inconsistent terms or conditions
                    contained, or referred to, elsewhere or as implied by law, trade custom, practice or course of dealing.
                </li>
                <li>1.2. The agreement is between us and you, the person or entity registering to be a participant in the Programme (“you”) and which is subject to these Terms (“Contract”), shall come into effect upon us emailing you to confirm our acceptance of your registration form for the Programme and shall continue until terminated in accordance with these Terms.</li>
                <li>1.3. If you are joining online, the process will be as follows:</li>
                <li>1.3.1. complete the Physical Activity Readiness form and return to admin@agewellsolutions.co.uk</li>
                <li>1.3.2. we will send you an email acknowledging acceptance and a link to register on the site.</li>
                <li>1.3.3. If at any stage you have made an error in your form, you may email us at admin@agewellsolutions.co.uk to correct any errors.</li>
                <li>1.4. If you are joining online, you should print a copy of these Terms for your records as we will not be filing a copy and we may change these Terms from time to time.</li>
                <li>1.5. These Terms should be read in conjunction with our Privacy Policy, which can be found on our website at https://app.agewellsolutions.co.uk/privacy (“site”).</li>
                <li>1.6. Where you are a corporate entity, “you” as used in these Terms shall be deemed to include your officers and employees and you shall procure that such officers and employees fully comply with these Terms.</li>
            </ol>
            <ol>
                2. Programme
                <li>2.1. The Programme will be provided over the course of 12 weeks from the date of registration. Access to the online course part of the Programme shall be available immediately on registration for 12 weeks from the date of registration. The Programme shall be delivered by a combination of online sessions, and one on one in person sessions (“One to One Session”). The Programme is more particularly described in the Schedule to these Terms.</li>
                <li>2.2. The date and time of all One to One Sessions are as set out on the site or as otherwise communicated to you but are subject to change. We will provide you with as much notice of any change as is possible but we shall not be liable to you in any way for any change to such dates or times. Please check the site regularly for updates on changes to dates and times.</li>
                <li>2.3. The materials we deliver as part of the Programme do not in any way constitute advice or recommendations. We are providing information and guidance only. We are not able to advise you on your individual circumstances.</li>
                <li>
                    <b>One to One Sessions</b>
                </li>
                <li>2.4. If you can't attend a scheduled One to One Session, we shall endeavour to try to reschedule such session but if we are not able to do so, we can offer a session delivered online.</li>
                <li>2.5. If you arrive late for a One to One Session, we will try to extend the end time but if this is not possible, that session will end at the scheduled time.</li>
                <li>2.6. You are responsible for your own belongings that you take to a One to One Session you attend in person, and we will not be liable for any loss, damage, theft or destruction of any of your belongings.</li>
                <li>2.7. You agree to indemnify us against any claim from any third party (and associated costs and expenses (including professional fees)) arising out of your actions or inactions while at a venue as part of the Programme.</li>
                <li>
                    <b>Online Sessions</b>
                </li>
                <li>2.8. You agree to keep user details and your password for the site confidential at all times and to not disclose them to any third party. You must notify us immediately if you become aware of any unauthorised use of your account and you shall indemnify us against all claims, damages, losses, costs or expenses (including professional fees) and any other liability which arises from any unauthorised use of your account.</li>
                <li>
                    2.9. Any digital materials included in the Programme require the following hardware and software and other functional requirements in order to be fully used: mobile phone or tablet or laptop with video enabled. <b>For more information, please visit the following links:</b>
                    <ul>
                        <li>https://dmd.uconn.edu/computerrequirements/?fbclid=IwAR1a8zOfcjKnzdNvk6lznuPm7shB_kqSdFEIrYPxGh2ycJhg2m4v5y1pVkY</li>
                        <li>https://support.pix4d.com/hc/en-us/articles/202557289-System-requirementsMinimum-and-recommended-computer-specifications</li>
                    </ul>
                </li>
            </ol>
            <ol>
                3. Goods
                <li>3.1. Where we are providing goods or products blood pressure monitors (“Products”) as part of the Programme, the following terms and conditions of this clause 3 shall apply.</li>
                <li>3.2. Any photographs of the Products on our site are for illustration purposes only.</li>
                <li>3.3. Your order will be fulfilled by the estimated delivery date set out in our confirmation email or as described on our site, unless there is an event outside of our control. If we are unable to meet the estimated delivery date because of an event outside our control, we will endeavour to contact you with a revised estimated delivery date.</li>
                <li>3.4. Delivery will be completed when we deliver the Products to the address you gave us when you registered on the Programme and the Products will be your responsibility from the completion of delivery.</li>
            </ol>
            <ol>
                4. Payment
                <li>4.1. There is no payment required for the Programme.</li>
                <li>4.2. You shall be responsible for any testing, equipment, travel and all other expenses incurred by you in connection with your participation in the Programme.</li>
            </ol>
            <ol>
                5. Our obligations
                <li>5.1. The Programme and any materials we provide to you as part of the Programme (“Programme Materials”) will be of satisfactory quality and reasonably fit for the purpose for which the Programme is supplied.</li>
                <li>5.2. Other than as set out in clause 5.1 above, all warranties and representations are excluded to the fullest extent permitted by law. Due to the nature of coaching and the fact that your success is dependent on a number of factors over which we have no control, we do not guarantee any particular results.</li>
                <li>5.3. We will endeavour to ensure that all information that we provide is accurate and up-to-date but we shall not be liable for any claims arising from such information being inaccurate or not up-to-date or otherwise.</li>
            </ol>
            <ol>
                6. Intellectual Property
                <li>6.1. We are the owner or the licensee of all intellectual property rights (including without limitation copyright, trade marks, and rights in designs) and all other rights in the Programme and the Programme Materials and nothing in these Terms or otherwise shall operate to transfer the ownership of the intellectual property rights in the Programme or Programme Materials to you or to any other person.</li>
                <li>6.2. You may not at any time copy, reproduce, publish in any form, share, sell, dispose of or otherwise make available to a third party in any way any of the content or materials (or any of the ideas and concepts created by us and contained in the content or materials) contained in the Programme or the Programme Materials.</li>
                <li>6.3. We grant to you a limited, non-exclusive, non-transferable, non-sub licensable revocable licence to use all or any of the content of the Programme and Programme Materials for the purposes for which they were provided only.</li>
                <li>6.4. Except as set out in clause 6.3, you may not use any of our intellectual property rights at any time except where duly licensed. Use of our logo is strictly prohibited without our prior written consent.</li>
                <li>6.5. You may not without our prior written consent make any audio or visual recordings of any part of our Programme.</li>
                <li>6.6. You are not permitted to sell or promote products or services to other participants in the Programme at or during any part of our Programme without our prior written permission. You shall not contact any participants of the Programme other than in relation to progressing within the Programme.</li>
                <li>6.7. The provisions of this clause 6 shall survive termination of the Contract.</li>
            </ol>
            <ol>
                7. Confidentiality
                <li>
                    7.1. During the Programme, you may have access to our confidential information, in particular relating to our business or any other Programme member. You may also share with us confidential information about your home and working life. By accepting these Terms, you agree that you will not use or disclose to any third party any confidential information you receive, and you will use your best endeavours to prevent the publication of any confidential information relating to us
                    or any other Programme member. We are also bound by confidentiality obligations and will not share your confidential information outside of our company.
                </li>
                <li>7.2. You accept that any unauthorised disclosure of confidential information belonging to us or Programme members may amount to immediate dismissal from the Programme, without liability. You remain bound by the confidentiality obligations stated in these Terms after the Programme ends.</li>
            </ol>
            <ol>
                8. Privacy and Data Protection
                <li>We respect your right to privacy, and so when you process your personal data before and during the Programme, we comply with the Data Protection Act 2018 and EU law retained version of the General Data Protection Regulation ((EU) (2016/679). For more information on how we process your personal data, please refer to our Privacy Policy available at https://app.agewellsolutions.co.uk/privacy and our Cookie Policy available at https://app.agewellsolutions.co.uk/cookies.</li>
            </ol>
            <ol>
                9. Term and termination
                <li>9.1. The Contract shall continue until the end of the Programme when the Contract shall expire other than for the Terms that are specifically stated to remain in force. For the avoidance of doubt, the licence granted in clause 6.3 shall terminate automatically on termination or expiry of the Contract.</li>
                <li>9.2. Notwithstanding the provision of clauses 9.1, either of us may terminate the Contract on written notice to the other with immediate effect if at any time:</li>
                <li>9.2.1. the other commits any serious or repeated breach or non-observance of any of the provisions of these Terms; or</li>
                <li>
                    9.2.2. the other (i) makes a resolution for its winding up, (ii) makes an arrangement or composition with its creditors, (iii) makes an application to a court of competent jurisdiction for protection from its creditors, (iv) is unable to pay its debts, (v) ceases trading or an administration or winding-up order is made or an administrator or receiver is appointed in relation to such party, (vi) is declared bankrupt or (vii) is convicted of a custodial offence (other than a
                    road traffic offence); or
                </li>
                <li>9.2.3. the other party commits any fraud or dishonesty or acts in any manner which in the opinion of the terminating party brings or is likely to bring the terminating party into disrepute or is materially adverse to the interests of the terminating party.</li>
                <li>9.3. We may terminate the Contract if your continued participation in the Programme is in our opinion causing disruption to the running of the Programme or to other participants.</li>
                <li>9.4. Termination of the Contract shall not affect the accrued rights, remedies, obligations and liabilities of either of us as at the date of termination of this Contract, including the right to claim damages in respect of any breach of the Contract which existed at or before the date of termination.</li>
                <li>9.5. Any delay by us in exercising our right to terminate the Contract shall not constitute a waiver of our right to terminate or to seek any other remedy.</li>
                <li>9.6. Clauses which expressly or by implication have effect after termination of the Contract shall continue in full force and effect after the date of termination of the Contract.</li>
                <li>9.7. This clause 9 shall survive termination of the Contract.</li>
                <li>9.8. Where the Contract expires, this shall be treated as a termination for the purposes of clause</li>
                <li>9.7 and all other clauses that refer to “termination”.</li>
            </ol>
            <ol>
                10. Liability
                <li>10.1. Nothing in this these Terms shall limit our liability for death or personal injury caused by our negligence or for our fraud or fraudulent misrepresentation or for any matter for which liability cannot be legally excluded or limited.</li>
                <li>10.2. We shall not be liable for any loss of profits, loss of business, depletion of goodwill and/or similar losses, loss of anticipated savings, loss of goods, loss of contract, loss or corruption of data or information or any special, indirect, consequential or pure economic loss, costs, damages, charges or expenses suffered or incurred by the you as a result of you entering into the Contract and/or us providing the Programme.</li>
                <li>
                    10.3. Our total liability in contract, tort (including negligence or breach of statutory duty), misrepresentation, restitution or otherwise arising in connection with the performance or contemplated performance of the Contract shall in all circumstances be limited to directly foreseeable loss if you are a consumer, or £500 if you are a business customer. 10.4. Any claims to be brought under or arising out of the Contract must be brought within 6 months of the date of the
                    event giving rise to the claim.
                </li>
                <li>10.5. If we are prevented from or delayed in performing our obligations by your act or omission or by any circumstance outside of our control (including our illness or incapacity or that of any member of the team involved in providing the Programme), we shall not be liable to you for any costs, charges or losses sustained or incurred by you that arise directly or indirectly from such prevention or delay.</li>
                <li>10.6. We may make changes (and shall not be liable for any additional costs incurred by you or for any other liability incurred by you as a result of changes) in (i) the Programme, (ii) any other content, (iii) the location of venues, (iv) the time and date of sessions or (v) the delivery mode of the sessions (for example changing an in person session to an online session) (vi) trainers, instructors or coaches.</li>
                <li>10.7. The provisions of this clause 8 shall survive termination of the Contract.</li>
                <li>10.8. You acknowledge and agree that:</li>
                <li>10.8.1. the Contract constitutes the entire agreement and understanding between us and supersedes any previous arrangement, understanding or agreement between us relating to the provision of the Programme (which shall be deemed to have been terminated by mutual consent); and</li>
                <li>10.8.2. in entering into the Contract you have not relied on any undertaking, promise, assurance, statement, representation, warranty or understanding (whether in writing or not) of any person (whether party to the Contract or not) relating to the provision of the Programme other than as expressly set out in the Contract.</li>
            </ol>
            <ol>
                11. General
                <li>11.1. By registering for our Programme you warrant that:</li>
                <li>11.1.1. you are legally capable of entering into binding contracts; and</li>
                <li>11.1.2. you are at least 18 years old; and</li>
                <li>11.1.3. that all information you provide us with is materially true and accurate at all times and not misleading in any way.</li>
                <li>11.2. You accept that communication with us will be mainly electronic. We will contact you by email or provide you with information by posting notices on our site. You agree to this electronic means of communication and you acknowledge that all contracts, notices, information and other communications that we provide to you electronically comply with any legal requirement that such communications be in writing.</li>
                <li>
                    11.3. We may vary these Terms as we see fit from time to time and if we do, we shall notify you by email of the change of terms. Your continuation with the Programme will be deemed to be your acceptance of any new Terms. Where there has been a material change to the Terms, if you do not wish to accept the new Terms and provide us with written notice of this within 7 days of our email to you setting out the changes to the terms, the Contract will terminate immediately without
                    further notice.
                </li>
                <li>11.4. The Contract is personal to you and you may not assign, transfer, charge, subcontract, sublicense or deal in any other manner with all or any of your rights under the Contract.</li>
                <li>11.5. We may transfer, assign, charge, sub-contract or otherwise dispose of a Contract, or any of our rights or obligations arising under it, at any time during the term of the Contract.</li>
                <li>
                    11.6. If we fail to insist upon strict performance of any of your obligations under the Contract, or if we fail to exercise any of the rights or remedies to which we are entitled under the Contract, this shall not constitute a waiver of such rights or remedies and shall not relieve you from compliance with such obligations. A waiver by us of any default shall not constitute a waiver of any subsequent default. No waiver by us of any of these Terms shall be effective unless it
                    is expressly stated to be a waiver and is in writing.
                </li>
                <li>11.7. If any of these Terms are determined by any competent authority to be invalid, unlawful or unenforceable to any extent, such term, condition or provision will to that extent be severed from the remaining terms, conditions and provisions which will continue to be valid to the fullest extent permitted by law.</li>
                <li>11.8. A person who is not a party to the Contract shall not have any rights under the Contracts (Rights of Third Parties) Act 1999 to enforce any term of the Contract.</li>
                <li>11.9. The rights of the parties to terminate, rescind or agree any variation, waiver or settlement under the Contract are not subject to the consent of any person that is not a party to the Contract.</li>
                <li>11.10. The Contract and any dispute or claim arising out of or in connection with it or its subject matter or formation (including non-contractual disputes or claims) shall be governed by and construed in accordance with the laws of Northern Ireland.</li>
                <li>11.11. We each irrevocably agree that the courts of Northern Ireland shall have exclusive jurisdiction to settle any dispute or claim that arises out of or in connection with the Contract or its subject matter or formation (including non-contractual disputes or claims).</li>
                <li>11.12. Unless the context otherwise requires, a reference to one gender shall include a reference to the other gender.</li>
            </ol>
            <br /> <br />
            <h1>ANNEX 1</h1>
            <h2>CANCELLATION FORM</h2>
            <p>
                To Agewell Solutions Ltd, 29 Bridge Street, Lisburn, admin@agewellsolutions.co.uk
                <br />
                I/we* hereby give notice that I/we* cancel my/our* contract for the supply of the following services: ………………………………………………………………………………………………………………………………………
                <br />
                Registered on ………………………………………………………………………………………………………
                <br />
                Name of participant ………………………………………………………………………………………………………
                <br />
                Address of participant ………………………………………………………………………………………………………
                <br />
                Signature of participant ………………………………………………………………………………………………………
                <br />
                Date ………………………………………………………………………………………………………
                <br />
                {'[* delete as appropriate]'}
                <br />
                <br /> <br />
                <h1>SCHEDULE</h1>
                <h2>DESCRIPTION OF THE PROGRAMME</h2>
                <p>
                    The Programme is all about fostering a lasting commitment to physical activity while giving you the support you need to thrive. We're here to help you transform physical activity into a daily habit, making it an integral part of your lifestyle. But here's the exciting part - you get to choose how you engage! Whether it's through personal trainers, fitness groups, or any other avenue that resonates with you, we're here to provide the technical support you need. Your journey to
                    a healthier, more active you is a partnership, and we're excited to be a part of it. Let's make your fitness goals a reality together!" The Programme for busy perimenopausal and menopausal women is delivered by Emma Hughes and Barbara Bray of Agewell and starts in the first week of October 2023. We use an online platform for you to log your physical activity, blood pressure, waist measurement pulse rate and additional blood test results e.g. cholesterol and blood sugar.
                </p>
            </p>
            <table className='border'>
                <thead>
                    <tr>
                        <td></td>
                        <td>Digital</td>
                        <td>Hybrid</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Accountability to help you track activity and health and stick to your goals</td>
                        <td>✓</td>
                        <td>✓</td>
                    </tr>
                    <tr>
                        <td>A physical activity questionnaire</td>
                        <td>✓</td>
                        <td>✓</td>
                    </tr>
                    <tr>
                        <td>Pre-start 3-minute YMCA fitness test</td>
                        <td>✓</td>
                        <td>✓</td>
                    </tr>
                    <tr>
                        <td>Blood pressure check, waist measurement then 4 weekly check</td>
                        <td>Self</td>
                        <td>in person at Lisburn</td>
                    </tr>
                    <tr>
                        <td>Mobile-friendly tracker to measure waist, blood pressure and mood</td>
                        <td>✓</td>
                        <td>✓</td>
                    </tr>
                    <tr>
                        <td>Content on activity and health advice</td>
                        <td>✓</td>
                        <td>✓</td>
                    </tr>
                    <tr>
                        <td>Completion blood pressure check and waist measurement</td>
                        <td>Self</td>
                        <td>in person at Lisburn</td>
                    </tr>
                    <tr>
                        <td>Completion YMCA fitness test</td>
                        <td>Self</td>
                        <td>in person at Lisburn</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}
