import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { api } from '../services/api.service'

import Loading from '../components/loading'
import PageHeader from '../components/page-header'
import Table from '../components/table'

export default function AdminUserData(props) {
    const params = useParams()
    let id = params.id ?? props.id

    const [data, setData] = useState(null)

    useEffect(() => {
        api(`${process.env.REACT_APP_API_URL}/admin/user/${id}/data`).then((x) => setData(x))
    }, [])

    if (!data) return <Loading></Loading>

    console.log(data)

    return (
        <>
            <PageHeader headline={'User Data'}></PageHeader>

            <div className='flex'>
                <div className='w-full max-w-5xl pb-20 mx-auto my-10 2xl:max-w-6xl'>
                    <div>
                        <div className='grid grid-cols-3 p-4 mx-4 my-2 bg-white border border-gray-300 gap-x-4'>
                            <div className='flex flex-col space-y-2'>
                                <div className='font-bold'>Name</div>
                                <div>{data.user.name}</div>
                            </div>
                            <div className='flex flex-col space-y-2'>
                                <div className='font-bold'>Email</div>
                                <div>{data.user.email}</div>
                            </div>
                            <div className='flex flex-col space-y-2'>
                                <div className='font-bold'>Target Minutes</div>
                                <div>{data.user?.targetMinutes ?? 'Not set'}</div>
                            </div>
                        </div>
                        <div className='pt-6 m-4 border-t border-gray-300'>
                            <h1 className='text-xl'>Activity</h1>
                            {data.activity.length ? (
                                <Table
                                    data={data.activity}
                                    columns={[
                                        {
                                            Header: 'Timestamp',
                                            accessor: 'timestamp',
                                        },
                                        {
                                            Header: 'Title',
                                            accessor: 'activityTitle',
                                        },
                                        {
                                            Header: 'Duration',
                                            accessor: 'activityDuration',
                                        },
                                    ]}
                                />
                            ) : (
                                <div>User has not recorded any activity</div>
                            )}
                        </div>

                        <div className='pt-6 m-4 border-t border-gray-300'>
                            <h1 className='text-xl'>Health Metrics</h1>
                            {data.metrics.length ? (
                                <Table
                                    data={data.metrics}
                                    columns={[
                                        {
                                            Header: 'Timestamp',
                                            accessor: 'timestamp',
                                        },
                                        {
                                            Header: 'Metric Name',
                                            accessor: 'metricName',
                                        },
                                        {
                                            Header: 'Value',
                                            accessor: 'metricValue',
                                        },
                                    ]}
                                />
                            ) : (
                                <div>User has not recorded any health metrics</div>
                            )}
                        </div>

                        <div className='pt-6 m-4 border-t border-gray-300'>
                            <h1 className='text-xl'>Ratings</h1>

                            {data.ratings.length ? (
                                <Table
                                    data={data.ratings}
                                    columns={Object.keys(data.ratings[0]).map((key) => {
                                        return {
                                            Header: key,
                                            accessor: key,
                                        }
                                    })}
                                />
                            ) : (
                                <div>User has not recorded any ratings</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
