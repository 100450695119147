import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { PasswordField, TextAreaField, TextField } from '../forms/fields'

import AuthLayout from './layout'

export default function Register() {
    const [values, setValues] = useState({})
    const [error, setError] = useState('')
    const [searchParams, setSearchParams] = useSearchParams()
    const [confirm, setConfirm] = useState(false)

    async function submit() {
        await fetch(`${process.env.REACT_APP_API_URL}/auth/register`, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
            }),
            body: JSON.stringify({ user: values, metaData: { ref: searchParams.get('r'), client: searchParams.get('c') } }),
        })
            .then((res) => {
                return res.json()
            })
            .then((x) => {
                if (x.error) {
                    if (x.error === 'email_in_use') {
                        setError('Email already in use. Please sign in or register with a different email.')
                    }
                } else if (x.token) {
                    localStorage.setItem('token', x.token)
                    if (searchParams.get('rd')) {
                        window.location.replace(searchParams.get('rd'))
                    } else {
                        window.location.replace('/')
                    }
                } else {
                    setError('Registration failed. Please check your details and try again.')
                }
            })
    }

    return (
        <>
            <ConfirmConsent
                open={confirm}
                confirm={() => {
                    setConfirm(false)
                    submit()
                }}
                cancel={() => setConfirm(false)}
            />
            <AuthLayout>
                <div className='text-xl font-medium text-center text-neutral-800'>Create your account</div>
                <div className='my-5 text-sm font-light text-center text-neutral-500'>
                    Already have an account?{' '}
                    <a className='text-green-400 hover:text-green-500' href='/login'>
                        Sign In
                    </a>
                </div>
                <div className='space-y-6'>
                    <div>
                        <label className='block text-sm font-medium text-center text-neutral-800'>{error}</label>
                    </div>

                    <TextField required label='Name' value={values.name} onChange={(val) => setValues({ ...values, name: val })}></TextField>

                    <TextField required email label='Email' value={values.email} onChange={(val) => setValues({ ...values, email: val })}></TextField>

                    <div className='grid grid-cols-2 gap-4'>
                        <PasswordField required email label='Password' value={values.password} onChange={(val) => setValues({ ...values, password: val })}></PasswordField>
                        <PasswordField required email label='Confirm Password' value={values.confirmPassword} onChange={(val) => setValues({ ...values, confirmPassword: val })}></PasswordField>
                    </div>

                    <div className="text-sm text-neutral-700">
                        Please provide us with some basic demographic information to help us better understand your health needs. This information will be kept confidential and will only be used to provide you with relevant health information.

                        <div className='grid grid-cols-3 gap-4'>
                            <TextField label='Year Of Birth' value={values.yearOfBirth} onChange={(val) => setValues({ ...values, yearOfBirth: val })}></TextField>
                            <TextField label='Sex' value={values.sex} onChange={(val) => setValues({ ...values, sex: val })}></TextField>
                            <TextField label='Ethnicity' value={values.ethnicity} onChange={(val) => setValues({ ...values, ethnicity: val })}></TextField>
                        </div>
                    </div>

                    <TextAreaField label='Address' value={values.address} onChange={(val) => setValues({ ...values, address: val })}></TextAreaField>

                    <div className='flex flex-col space-y-4'>
                        <div className='flex flex-row'>
                            <input checked={values.tos} onChange={(e) => setValues({ ...values, tos: e.target.checked })} type='checkbox' className='block appearance-none rounded border mt-0.5 border-gray-200  placeholder-gray-400 shadow-sm focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm' />

                            <div className='ml-2 text-sm text-light text-neutral-500'>
                                I accept the platform{' '}
                                <a target='_blank' href='/terms' className='text-green-400 hover:text-green-500'>
                                    Terms of Service
                                </a>
                            </div>
                        </div>
                        <div className='flex flex-row'>
                            <input checked={values.consent} onChange={(e) => setValues({ ...values, consent: e.target.checked })} type='checkbox' className='block appearance-none rounded border mt-0.5 border-gray-200  placeholder-gray-400 shadow-sm focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm' />

                            <div className='ml-2 text-sm text-light text-neutral-500'>
                                I have signed and agree to the{' '}
                                <a target='_blank' href='/consent-form' className='text-green-400 hover:text-green-500'>
                                    Consent Form
                                </a>
                            </div>
                        </div>
                    </div>

                    <div>
                        <button onClick={() => setConfirm(true)} type='button' disabled={!values.email || !values.password || values.password != values.confirmPassword || !values.tos || !values.consent} className='flex justify-center w-full px-4 py-2 font-semibold text-white bg-green-500 border border-transparent rounded shadow-sm disabled:bg-gray-500 text-md hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2'>
                            Sign Up
                        </button>
                    </div>
                </div>
            </AuthLayout>
        </>
    )
}

function ConfirmConsent(props) {
    const cancelButtonRef = useRef(null)

    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog as='div' className='relative z-40' initialFocus={cancelButtonRef} onClose={() => props.cancel()}>
                <Transition.Child as={Fragment} enter='ease-out duration-300' enterFrom='opacity-0' enterTo='opacity-100' leave='ease-in duration-200' leaveFrom='opacity-100' leaveTo='opacity-0'>
                    <div className='fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75' />
                </Transition.Child>

                <div className='fixed inset-0 z-10 overflow-y-auto'>
                    <div className='flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0'>
                        <Transition.Child as={Fragment} enter='ease-out duration-300' enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95' enterTo='opacity-100 translate-y-0 sm:scale-100' leave='ease-in duration-200' leaveFrom='opacity-100 translate-y-0 sm:scale-100' leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
                            <Dialog.Panel className='relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-lg sm:p-6'>
                                <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                                    <Dialog.Title as='h3' className='text-lg font-medium leading-6 text-neutral-700'>
                                        By signing up, you consent to data being securely stored
                                    </Dialog.Title>
                                    <div className='mt-2'>
                                        <p className='text-sm text-gray-500'>
                                            For full programme details, terms and cancellation policy, please read the platform{' '}
                                            <a target='_blank' href='/terms-of-service' className='text-green-400 hover:text-green-500'>
                                                Terms of Service
                                            </a>
                                        </p>
                                    </div>
                                    <div className='mt-2'>
                                        <p className='text-sm text-gray-500'>
                                            For details of the agreements made, please read the{' '}
                                            <a target='_blank' href='/consent-form' className='text-green-400 hover:text-green-500'>
                                                Consent Form
                                            </a>
                                        </p>
                                    </div>
                                </div>
                                <div className='mt-5 sm:mt-4 sm:flex sm:flex-row-reverse'>
                                    <button type='button' className='inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-green-600 border border-transparent rounded shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm' onClick={() => props.confirm()}>
                                        Confirm
                                    </button>
                                    <button type='button' className='inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-200 rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm' onClick={() => props.cancel()} ref={cancelButtonRef}>
                                        Cancel
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
