import moment from 'moment'
import { PencilIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { api } from '../services/api.service'

import Button from '../components/button'
import Card from '../components/card'

import { DateField, NumberField, TextField } from '../forms/fields'
import FormWrapper from '../forms/form-wrapper'

import FeelingsCard from './feelings-card'

export default function ActivityView() {
    const [user, setUser] = useState(null)
    const [activity, setActivity] = useState(null)
    const [streak, setStreak] = useState(null)
    const activityToday = activity?.filter(({ date }) => moment().isSame(moment(date), 'date')) ?? []

    useEffect(() => {
        loadUser()
        loadActivity()
    }, [])

    function loadUser() {
        api(`${process.env.REACT_APP_API_URL}/app/user`).then((x) => setUser(x))
    }

    function loadActivity() {
        api(`${process.env.REACT_APP_API_URL}/app/activity`).then((x) => setActivity(x))
    }

    function updateTargetMinutes(newTarget) {
        api(`${process.env.REACT_APP_API_URL}/app/user`, { data: { targetMinutes: newTarget } }).then((x) => {
            toast.success('Target Saved')
            loadUser()
        })
    }

    useEffect(() => {
        if (!activity || activity.length === 0) {
            setStreak([])
            return
        }

        const hardStop = new Date(activity.reduce((oldest, { date }) => (oldest = date.valueOf() < oldest.valueOf() ? date : oldest))).valueOf()

        let dt = moment()
        let minutes = activity.filter(({ date }) => dt.isSame(moment(date), 'date'))?.reduce((total, { duration }) => (total += duration), 0) ?? 0

        if (minutes < user?.targetMinutes ?? 0) {
            setStreak([])
        } else {
            let streakDays = []
            while ((minutes >= user?.targetMinutes ?? 0) && dt.valueOf() > hardStop) {
                streakDays.push(minutes)
                dt = dt.subtract(1, 'days')
                minutes = activity?.filter(({ date }) => dt.isSame(moment(date), 'date')).reduce((total, { duration }) => (total += duration), 0) ?? 0
            }
            setStreak(streakDays)
        }
    }, [activity, user])

    return (
        <div className='flex flex-col space-y-4'>
            <div className='grid grid-cols-1 gap-4 sm:grid-cols-4'>
                <Card className='sm:col-span-1'>
                    <SetTarget user={user} onConfirm={(newTarget) => updateTargetMinutes(newTarget)} />
                </Card>
                <Card className='sm:col-span-3'>
                    {streak && (
                        <div className='mx-4 mb-4'>
                            <div className='mt-2 mb-4 text-accent-pink'>Streak</div>
                            <div className='flex flex-col items-center'>
                                {streak.length === 0 && <div className='text-xl text-center text-sky-800'>{user?.targetMinutes ? <>Record {activityToday?.length > 0 ? `${(user?.targetMinutes ?? 0) - activityToday.reduce((t, d) => (t += d.duration), 0)} more minutes` : 'an activity'} to start a new streak!</> : 'Set an activity target to start tracking streaks'}</div>}
                                {streak.length === 1 && (
                                    <div className='text-center'>
                                        <div className='text-xl text-sky-800'>Today you started a new streak!</div>
                                        <div className='text-sky-700'>Remember to come back tomorrow to keep it going</div>
                                    </div>
                                )}
                                {streak.length > 1 && (
                                    <div className='flex flex-col text-center'>
                                        <div className='flex items-end justify-center text-sky-800'>
                                            <div className='text-3xl font-bold'>{streak.length}</div>
                                            <div className='text-lg'>&nbsp;day streak!</div>
                                        </div>
                                        <div className='text-sky-700'>{exceedByText(streak, user.targetMinutes)}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </Card>
            </div>

            {activityToday.length > 0 && (
                <Card>
                    <div className='mx-4 mb-4'>
                        <div className='mt-2 mb-4 text-accent-pink'>Today</div>
                        {activityToday.length === 1 && (
                            <div className='flex flex-col items-center'>
                                <div className='text-3xl font-bold leading-9 text-sky-800'>{activityToday[0].duration} min</div>
                                <div className='text-lg font-semibold text-center text-sky-700'>{activityToday[0].title}</div>
                            </div>
                        )}
                        {activityToday.length > 1 && (
                            <div className='flex flex-col sm:justify-center sm:space-x-4 sm:flex-row text-sky-800'>
                                <div className='flex flex-col items-center'>
                                    <div className='text-3xl font-bold leading-9'>{activityToday.reduce((tot, d) => (tot += d.duration), 0)}</div>
                                    <div className='text-lg font-semibold text-center sm:w-min'>Active Minutes</div>
                                </div>
                                <div className='hidden leading-9 sm:block'>=</div>
                                <div className='flex justify-center mt-2 space-x-4 sm:justify-start sm:mt-0'>
                                    {activityToday.map((d, idx) => (
                                        <>
                                            {idx > 0 && <div className='leading-9'>+</div>}
                                            <div key={d.title + idx} className='flex flex-col items-center'>
                                                <div className='text-lg font-bold leading-9'>{d.duration}</div>
                                                <div className='text-lg text-sky-700'>{d.title}</div>
                                            </div>
                                        </>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </Card>
            )}

            <Card header='Record an Activity'>
                <div className='m-4'>
                    <FormWrapper
                        url='app/activity'
                        id={'new'}
                        defaultValue={{ date: new Date(), title: '', duration: 10 }}
                        noStyle
                        callback={() => {
                            toast.success("You're doing great!", {
                                position: 'top-center',
                                className: 'mt-[40vh] text-3xl',
                                style: {
                                    padding: '1.5rem',
                                },
                            })
                            loadActivity()
                        }}
                    >
                        {(values, setValues) => (
                            <div className='flex flex-col space-y-4'>
                                <div className='grid grid-cols-1 gap-4 sm:grid-cols-2'>
                                    <TextField label='Type of Activity' value={values.title} onChange={(val) => setValues({ ...values, title: val })} />
                                    <div className='flex flex-wrap items-end justify-center sm:justify-start sm:flex-nowrap '>
                                        {['Walk', 'Run', 'Cycle', 'Swim'].map((d) => (
                                            <Button
                                                text={d}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    setValues({ ...values, title: d })
                                                }}
                                                className='mb-2 mr-2 h-min sm:mb-0 bg-sky-400 hover:bg-sky-500'
                                            />
                                        ))}
                                    </div>
                                </div>
                                <div className='grid grid-cols-1 gap-4 sm:grid-cols-3'>
                                    <NumberField label='Duration (minutes)' value={values.duration} min={10} step={10} onChange={(val) => setValues({ ...values, duration: val })} />
                                    <div className='flex flex-wrap items-end justify-center sm:justify-start sm:flex-nowrap sm:col-span-2'>
                                        {[20, 30, 60].map((d) => (
                                            <Button
                                                text={`${d} mins`}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    setValues({ ...values, duration: d })
                                                }}
                                                className='mb-2 mr-2 h-min sm:mb-0 whitespace-nowrap bg-sky-400 hover:bg-sky-500'
                                            />
                                        ))}
                                    </div>
                                </div>
                                <div>
                                    <DateField value={values.date} onChange={(val) => setValues({ ...values, date: moment(val).toDate() })} label='Date' className='w-fit' />
                                </div>
                            </div>
                        )}
                    </FormWrapper>
                </div>
            </Card>

            <FeelingsCard />
        </div>
    )
}

const SetTarget = ({ user, onConfirm }) => {
    const [isUpdating, setUpdating] = useState(false)
    const [state, setState] = useState()

    useEffect(() => {
        setState(user?.targetMinutes)
    }, [user])

    return (
        <div className='flex flex-col justify-between h-full pt-2 pb-4 mx-4 space-y-2'>
            {isUpdating ? (
                <>
                    <div className='flex items-start justify-between'>
                        <div className='text-accent-pink'>Target</div>
                    </div>
                    <NumberField value={state} onChange={(val) => setState(val)} label='Active Minutes per Day' />
                    <div className='grid grid-cols-2 gap-x-2'>
                        <Button
                            text='Save'
                            onClick={() => {
                                setUpdating(false)
                                onConfirm(state)
                            }}
                        />
                        <Button
                            text='Cancel'
                            onClick={(e) => {
                                e.preventDefault()
                                setUpdating(false)
                            }}
                            className=' bg-amber-400 hover:bg-amber-500'
                        />
                    </div>
                </>
            ) : (
                <>
                    <div className='flex items-start justify-between'>
                        <div className='text-accent-pink'>Target</div>
                        <PencilIcon className='w-5 h-5 mt-1 cursor-pointer text-sky-600' onClick={() => setUpdating(true)} />
                    </div>
                    <div className='text-3xl font-bold text-center text-sky-800'>{user?.targetMinutes ?? '-'}</div>
                    <div className='font-bold text-center text-sky-600'>minutes per day</div>
                </>
            )}
        </div>
    )
}

function exceedByText(streak, target) {
    let exceed = streak.map((d) => d - target)
    let avg = exceed.reduce((t, d) => (t += d), 0) / exceed.length
    // let percent = Math.round((avg / target) * 1000) / 100

    if (avg >= 5) {
        return (
            <p>
                You've been exceeding your target of <b className='text-lg'>{target}</b> active minutes by an average of <b className='text-lg'>{Math.round(avg)}</b> minutes
            </p>
        ) // , (${percent}%)
    }

    if (avg >= 0) {
        return (
            <p>
                You've been consistently meeting your target of <b className='text-lg'>{target}</b> active minutes
            </p>
        )
    }
}
