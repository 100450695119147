import Card from '../components/card'

const RESOURCES = {
    'starting-out': [
        { text: 'How to measure your blood pressure', link: 'https://youtu.be/GSNZVaW1Wg4?si=qObhwG3MgLkxDEss' },
        { text: 'How to measure your waist', link: 'https://youtu.be/4MajPk-vp8M?si=FCBq1R8naHW9R2ff' },
        { text: 'Online metronome for the YMCA 3 minute step test', link: 'https://www.metronomeonline.com/' },
        { text: 'YMCA Step test', link: 'https://make.headliner.app/download/b1439b4e-4794-474a-b9c8-732df7e0582c' },
    ],
    'getting-going': [
        { text: 'Moderate Intensity Activity', link: 'https://youtu.be/OMn8Tq5Eyao' },
        { text: '25 fast and easy ways to fit in 10 minutes of exercise', link: 'https://www.prevention.com/fitness/fitness-tips/a20468023/fitness-25-fast-and-easy-ways-to-fit-in-10-minutes-of-exercise/' },
        { text: 'Yoga', link: 'https://youtube.com/@yogawithadriene' },
        { text: 'Tracking your time', link: 'https://lauravanderkam.com/manage-your-time/' },
        { text: "What's the best thing we can do for our health?", link: 'https://youtu.be/aUaInS6HIGo' },
        { text: 'NHS resources', link: 'https://www.nhs.uk/better-health/get-active/' },
        { text: 'British Heart Foundation - Menopause and your heart', link: 'https://www.bhf.org.uk/informationsupport/heart-matters-magazine/medical/women/menopause-and-your-heart' },
    ],
}

export default function ResourcesView() {
    return (
        <div className='space-y-4'>
            <Card header='Starting Out'>
                <div className='m-6 space-y-4'>
                    {RESOURCES['starting-out'].map((d, i) => (
                        <LinkItem key={i} {...d} />
                    ))}
                </div>
            </Card>
            <Card header='Getting Going'>
                <div className='m-6 space-y-4'>
                    {RESOURCES['getting-going'].map((d, i) => (
                        <LinkItem key={i} {...d} />
                    ))}
                </div>
            </Card>
        </div>
    )
}

function LinkItem({ link, text }) {
    return (
        <div className=''>
            <a href={link} className=''>
                <h1 className='text-lg font-bold underline text-sky-900 hover:text-sky-600'>{text}</h1>
            </a>
        </div>
    )
}
