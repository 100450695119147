import moment from 'moment/moment'
import { MagnifyingGlassIcon, PencilIcon, XCircleIcon } from '@heroicons/react/24/outline'
import { Fragment, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { api } from '../services/api.service'

import Button from '../components/button'
import Card from '../components/card'

import { DateField, NumberField, SelectField, TextField } from '../forms/fields'
import FormWrapper from '../forms/form-wrapper'

export const METRIC_SUGGESTIONS = ['Waist Measurement', 'Pulse Rate', 'Systolic Pressure', 'Diastolic Pressure', 'LDL Cholesterol', 'HbA1c']

export default function MetricView() {
    const [metrics, setMetrics] = useState()
    const [updating, setUpdating] = useState(null)
    const [recordingOptions, setRecordingOptions] = useState([])
    const [filter, setFilter] = useState({ isSet: false, value: '', fn: (x) => true })

    useEffect(() => {
        loadData()
    }, [])

    useEffect(() => {
        setRecordingOptions(getOptions(metrics))
    }, [metrics])

    function loadData() {
        api(`${process.env.REACT_APP_API_URL}/app/metrics`).then((x) => setMetrics(x))
    }

    return (
        <div className='space-y-4'>
            <Card header='Record a Measurement'>
                <div className='p-4'>
                    <FormWrapper
                        url='app/metrics'
                        id={'new'}
                        noStyle
                        callback={() => {
                            toast.success('Metric Saved')
                            loadData()
                        }}
                        defaultValue={{
                            date: new Date(),
                            type: '',
                            value: '',
                        }}
                    >
                        {(values, setValues) => (
                            <div className='flex flex-col space-y-4'>
                                <div className='space-y-2'>
                                    <TextField label='Type of Metric' value={values.type} onChange={(val) => setValues({ ...values, type: val })} />
                                    <div className='flex flex-wrap items-end justify-center'>
                                        {METRIC_SUGGESTIONS.map((name) => (
                                            <Button
                                                key={name}
                                                text={name}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    setValues({ ...values, type: name })
                                                }}
                                                className='mb-2 mr-2 h-min bg-sky-400 hover:bg-sky-500'
                                            />
                                        ))}
                                    </div>
                                </div>
                                <div className='grid grid-cols-1 gap-2 sm:grid-cols-2'>
                                    <TextField label='Value' value={values.value} onChange={(val) => setValues({ ...values, value: val })} />
                                    <DateField label='Date of Measurement' value={values.date} onChange={(val) => setValues({ ...values, date: moment(val).toDate() })} />
                                </div>
                            </div>
                        )}
                    </FormWrapper>
                </div>
            </Card>

            {metrics && metrics.length > 0 && (
                <Card
                    header={
                        <div className='flex flex-col space-y-2 sm:space-y-0 sm:flex-row sm:items-center sm:justify-between sm:space-x-4'>
                            <div className=''>My Recordings</div>
                            <div className='flex items-center'>
                                <div className='mr-2'>{filter.isSet ? <XCircleIcon className='w-6 h-6 my-auto cursor-pointer' onClick={() => setFilter({ isSet: false, value: '', fn: (x) => true })} /> : <MagnifyingGlassIcon className='w-6 h-6 my-auto text-accent-pink' />}</div>
                                <SelectField className='mt-0' value={filter.value} options={recordingOptions} onChange={(val) => setFilter({ isSet: true, value: val, fn: (x) => x.type.trim().toLowerCase() === val.toLowerCase() })} />
                            </div>
                        </div>
                    }
                >
                    <div className='flex flex-col p-4 space-y-4'>
                        {metrics
                            .filter((d) => !filter.isSet || filter.fn(d))
                            .sort((a, b) => (a.date.valueOf() < b.date.valueOf() ? 1 : -1))
                            .map((met, idx) => (
                                <Fragment key={idx}>
                                    {updating === met._id ? (
                                        <FormWrapper
                                            url='app/metrics'
                                            id={met._id}
                                            className='w-full pb-2 border-y border-accent-blue'
                                            callback={() => {
                                                setUpdating(null)
                                                toast.success('Metric Updated')
                                                loadData()
                                            }}
                                            hideButton
                                            noStyle
                                        >
                                            {(values, setValues, submit) => (
                                                <>
                                                    <div className='grid grid-cols-1 sm:grid-cols-3 sm:gap-x-1 text-sky-800'>
                                                        <TextField label='Type of Metric' value={values.type} onChange={(val) => setValues({ ...values, type: val })} />
                                                        <NumberField label='Value' value={values.value} onChange={(val) => setValues({ ...values, value: val })} />
                                                        <DateField label='Date of Measurement' value={values.date} onChange={(val) => setValues({ ...values, date: moment(val).toDate() })} />
                                                    </div>
                                                    <div className='flex justify-end mt-2 space-x-2'>
                                                        <Button text='Submit' onClick={() => submit()} />
                                                        <Button
                                                            text='Cancel'
                                                            onClick={(e) => {
                                                                e.preventDefault()
                                                                setUpdating(null)
                                                            }}
                                                            className='bg-amber-400 hover:bg-amber-500'
                                                        />
                                                    </div>
                                                </>
                                            )}
                                        </FormWrapper>
                                    ) : (
                                        <div className='flex items-center justify-between'>
                                            <div className='flex flex-col items-center justify-between flex-1 sm:space-x-4 sm:flex-row'>
                                                <div className='text-lg text-sky-800'>
                                                    {met.type} - <span className='text-xl font-semibold'>{met.value}</span>
                                                </div>
                                                <div className='text-sky-700'>{moment(met.date).format('Do MMM YYYY')}</div>
                                            </div>
                                            <div onClick={() => setUpdating(met._id)} className='ml-2 cursor-pointer'>
                                                <PencilIcon className='w-6 h-7 text-sky-700' />
                                            </div>
                                        </div>
                                    )}
                                </Fragment>
                            ))}
                    </div>
                </Card>
            )}
        </div>
    )
}

function getOptions(metrics) {
    if (!metrics) {
        return []
    }
    let unique = [...new Set(metrics.map(({ type }) => type.trim().toLowerCase()))]
    return unique.map((d) => {
        return {
            text: prettyCase(d),
            value: d,
        }
    })
}

function prettyCase(str) {
    let words = str.split(' ')
    words = words.map((w) => {
        let chars = w.split('')
        chars[0] = chars[0].toUpperCase()
        return chars.join('')
    })
    return words.join(' ')
}
