import { Route, Routes } from 'react-router-dom'

import ErrorBoundary from './components/error-boundary'

import UserForm from './forms/user-form'

import AdminUserData from './admin/admin-user-data'
import AdminUsers from './admin/admin-users'
import AdminLayout from './admin/layout'
import About from './app/about'
import ActivityView from './app/activity-view'
import Home from './app/home'
import AppLayout from './app/layout'
import MetricView from './app/metric-view'
import ResourcesView from './app/resources-view'
import Login from './auth/login'
import Logout from './auth/logout'
import Register from './auth/register'
import RequestReset from './auth/request-reset'
import Consent from './legal/consent'
import Cookies from './legal/cookies'
import Privacy from './legal/privacy'
import Terms from './legal/terms'

export default function App() {
    return (
        <ErrorBoundary componentName='App (top level catch)'>
            <Routes>
                <Route path='/login' element={<Login />} />
                <Route path='/register' element={<Register />} />
                <Route path='/request-reset' element={<RequestReset />} />
                <Route path='/logout' element={<Logout />} />

                <Route path='/' element={<AppLayout />}>
                    <Route index element={<Home />} />
                    <Route path='activity' element={<ActivityView />} />
                    <Route path='metrics' element={<MetricView />} />
                    <Route path='resources' element={<ResourcesView />} />
                    <Route path='about' element={<About />} />
                </Route>

                <Route path='/admin' element={<AdminLayout />}>
                    <Route index element={<AdminUsers />} />
                    <Route path='manage-user/:id' element={<UserForm />} />
                    <Route path='user/:id/data' element={<AdminUserData />} />
                </Route>

                <Route path='/terms' element={<Terms />} />
                <Route path='/privacy' element={<Privacy />} />
                <Route path='/cookie-policy' element={<Cookies />} />
                <Route path='/consent-form' element={<Consent />} />
            </Routes>
        </ErrorBoundary>
    )
}
